import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { HTMLContent } from "../components/Content";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Vacature from "../components/Vacature-static";

export const JobsPageTemplate = ({ post, jobs }) => {
  return (
    <>
      <section className="max-w-2xl mx-auto mb-12">
        <HTMLContent
          className="text-lg mb-2 font-light  netlify-html text-center "
          content={post.html}
        />
      </section>
      <section className="flex flex-row  mx-auto max-w-5xl xl:mb-16 flex-wrap">
        {post.frontmatter.show_all_vacancies === true && (
          <div className=" mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-3">
            {jobs.edges.map((edge) => {
              let jobObj = edge.node;
              return (
                <Vacature
                  isSecond={false}
                  key={jobObj.id}
                  link={jobObj.fields.slug}
                  team={jobObj.department}
                  title={jobObj.id}
                  uren={jobObj.hours_short}
                />
              );
            })}
          </div>
        )}
      </section>
    </>
  );
};

const JobsPage = ({ data }) => {
  const { markdownRemark: post, allJobYaml: jobs } = data;
  return (
    <>
      <SEO
        description={post.frontmatter.seo.meta_description}
        pathname={post.frontmatter.path}
        title={post.frontmatter.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.frontmatter.hero_background_image}
        subtitle={post.frontmatter.subtitle}
        title={post.frontmatter.title}
      >
        <main className="container mx-auto px-6 py-10  max-w-7xl ">
          <JobsPageTemplate content={post.html} jobs={jobs} post={post} />
        </main>
      </Layout>
    </>
  );
};

JobsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default JobsPage;

export const jobsPageQuery = graphql`
  query JobsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        path
        hero_background_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        seo {
          meta_title
          meta_description
        }
        subtitle
        show_all_vacancies
      }
    }
    allJobYaml {
      edges {
        node {
          department
          hours_short
          id
          location
          fields {
            slug
          }
        }
      }
    }
  }
`;
