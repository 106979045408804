import { Link } from "gatsby";
import React from "react";

function VacatureCard(props) {
  let { title, team, link, uren, isSecond = false } = props;
  return (
    <div
      className={`flex w-full py-8 px-16  mb-6 bg-gladior-black text-white flex-col  md:max-w-xs  mt-4  xl:mt-0  ${
        isSecond === false ? "md:ml-6" : ""
      } `}
    >
      <div className="flex flex-col items-stretch h-full">
        <div className="flex  flex-col flex-grow">
          <h3 className="font-semibold text-2xl mb-2 font-display ">{title}</h3>
          <p className="mb-2 font-light  text-xs text-gray-600 mb-8">{team}</p>
        </div>

        <div className="flex  flex-col flex-grow-0">
          <div className="  ">
            <p className="pb-2 mb-8 mr-8 inline-block border-b-2 border-gladior-pink">
              Enschede
            </p>
            <p className="pb-2 mb-8 inline-block border-b-2 border-gladior-pink">
              {uren}
            </p>
          </div>

          <Link
            className="no-underline text-gladior-pink hover:underline  hover:text-gladior-pink"
            to={link}
          >
            Bekijk vacature
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VacatureCard;
